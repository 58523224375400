<template>
  <div class="subheader">
    <template v-if="metamaskAddress && contractCompanies && companies && Object.keys(companies).length">
      <div class="d-flex justify-content-center flex-row" v-if="contractCompanies">
        <div
          @click="chooseCompany(company.companyId)"
          v-for="(company, i) of companies"
          :key="i" 
          class="subheader-companies d-inline-flex align-items-center"
        >
          <h3 :class="chosenCompany === company.companyId ? 'active' : ''" class="mx-1">
            {{company.companyName}}
          </h3>
        </div>
      </div>
    </template>
  </div>
  <div
    v-if="(multisigContracts?.length || $props.type !== 'multisig') && $props.type !== 'registry' && companies && companies[chosenCompany] && Object.keys(companies[chosenCompany].tokens).length"
    class="sub-subheader"
  >
    <template v-if="$props.type === 'third-party'">
      <div class="mx-1 subsubheader-token" :class="chosenThirdPartyAccount === account ? 'active' : ''" @click="setChosenThirdPartyAccount(account)" :key="account" v-for="account of thirdPartyAccounts[1]">
        {{shortenAddress(account)}}
      </div>
    </template>
    <template v-else-if="$props.type === 'multisig'">
      <div 
        :class="chosenMultisigContract === multisigContract ? 'active' : ''"
        @click="setChosenMultisigContract(multisigContract)" 
        class="mx-1 subsubheader-token"
        :key="index"
        v-for="(multisigContract, index) of multisigContracts"
      >
        {{shortenAddress(multisigContract)}}
      </div>
    </template>
    <template v-else>
      <div
        class="mx-1 subsubheader-token"
        :class="token.tokenAddress === chosenToken ? 'active' : ''"
        @click="chooseToken(token.tokenAddress, companies[chosenCompany].companyId)"
        :key="index" 
        v-for="(token, index) of companies[chosenCompany].tokens"
      >
        {{token.tokenName}}
      </div>
    </template>
    <!-- {{$props.type}} -->
  </div>
  
  
  <div
    v-if="type !== 'login'"
    @click="closeModal"
    :class="showWalletModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <WalletModal
      :showWalletModal="showWalletModal"
      v-on:closeModal="closeModal"
    />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import router from "../router";
import { metamaskSetup } from "../main";
import metamaskIcon from "../assets/images/metamaskicon.svg";
import { proposeVote } from "../main";
import WalletModal from "./WalletModal.vue";
import { shortenAddress } from "../helpers";

export default defineComponent({
  name: "Header",
  props: ["type"],
  // emits: ["chooseCompany", "chooseStock"],
  components: {
    WalletModal,
  },
  data: function () {
    return {
      currentRoute: "",
      enteredAmount: "",
      inputAmount: "",
      toAddress: "",
      metamaskAddress: null,
      showWalletModal: false,
      metamaskIcon: metamaskIcon,
      contractCompanies: [],
      companyBalances: null,
      multisigEnabled: false,
      rendered: false,
      firstMount: true,
      chosenCompany: 0,
      chosenStock: 0,
      totalVotes: "",
      companies: {},
      chosenToken: null,
      chosenMultisigContract: null,
      multisigContracts: [],
      unsubscribe: null,
      firstLoad: true,
      thirdPartyAccounts: [],
      chosenThirdPartyAccount: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCompanies') {
        this.companies = null;
        this.companies = state.companies;
      }
      if (mutation.type === 'setThirdPartyAccounts') {
        this.thirdPartyAccounts = state.thirdPartyAccounts;
        this.setChosenThirdPartyAccount(state.thirdPartyAccounts[1][0]);
      }
      if (mutation.type === 'setChosenThirdPartyAccount') {
        this.chosenThirdPartyAccount = state.chosenThirdPartyAccount;
      }
      if (mutation.type === 'setChosenMultisigContract') {
        this.chosenMultisigContract = state.chosenMultisigContract;
      }
    });
    this.$store.watch((state) => {
      this.totalVotes = state.totalVotes;
      this.multisigEnabled = state.canUseMultisig;
      this.chosenCompany = state.chosenCompany;
      this.chosenToken = state.chosenStock;
      this.metamaskAddress = state.metamaskAddress;
      this.companies = null;
      this.companies = state.companies;
      if (state.metamaskAddress) {
        this.closeModal();
      }
      if (state.multisigContracts) {
        if (state.multisigContracts[1] && !Object.keys(state.multisigContracts[1]).length) {
          this.noMultisigs = true;
        } else {
          this.multisigContracts = state.multisigContracts[1];
          if (state.multisigContracts[1] && this.firstLoad) {
            this.firstLoad = false;
            this.setChosenMultisigContract(state.multisigContracts[1][0]);
          }
        }
      }
    });
    this.currentRoute = router.currentRoute._rawValue.fullPath;
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    setChosenThirdPartyAccount(thirdPartyAccount) {
      this.$store.dispatch('setChosenThirdPartyAccount', thirdPartyAccount);
    },
    shortenAddress(string) {
      return shortenAddress(string, 5);
    },
    handleAmountInput(e) {
      this.inputAmount = null;
      const cleanInput = e.target.value.replace(/\D/g, "");
      this.inputAmount = cleanInput;
    },
    metamaskSetup() {
      metamaskSetup();
    },
    handleVote() {
      proposeVote(this.toAddress, this.inputAmount)
        .then((res) => {
          console.log("proposal", res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseCompany(companyId) {
      this.chosenCompany = companyId;
      this.chosenToken = null;
      this.$store.dispatch('setChosenCompany', companyId)
      this.$store.dispatch('setChosenToken', null);
    },
    chooseToken(tokenAddress, companyId) {
      this.chosenToken = tokenAddress;
      this.chosenCompany = companyId;
      this.$store.dispatch('setChosenToken', tokenAddress);
      this.$store.dispatch('setChosenCompany', companyId);
    },
    setChosenMultisigContract(address) {
      this.chosenMultisigContract = address;
      this.$store.dispatch('setChosenMultisigContract', address);
    },
    closeModal() {
      this.showWalletModal = false;
    },
  },
  watch: {
    multisigContracts: function(newValue) {
      if (this.multisigContracts && this.multisigContracts.length) {
        this.$store.dispatch('setChosenMultisigContract', this.multisigContracts[0]);
      }
    }
  }
});
</script>